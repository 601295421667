.contact-container {
    max-width: 800px; /* Limits the width of the container */
    width: 800px; /* Fixed width of 500 pixels */
    margin: 40px auto; /* Centers the container on the page */
    padding: 20px; /* Adds padding inside the container */
    background-color: #f9f9f9; /* Very light grey background */
    border-radius: 8px; /* Rounded corners for the container */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow around the container */
    font-family: 'Arial', sans-serif; /* Font styling */
}

.contact-title {
    color: #2a2a2a; /* Dark grey color for the text */
    text-align: center; /* Center aligns the title */
    margin-bottom: 20px; /* Space below the title */
}

.contact-text {
    color: #4a4a4a; /* Medium grey color for the text */
    font-size: 16px; /* Font size for the paragraph */
    line-height: 1.5; /* Spacing between lines */
    text-align: center; /* Centers the text */
}
