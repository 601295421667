/* App.css */

/* Base styles */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh; /* Ensure it takes at least the full height of the viewport but can grow */
  padding: 20px;
}



.broderImage {
  max-width: 80%;
  max-height: 100%;
  width: auto;
  height: auto;
}

html {
  scroll-behavior: smooth;
}

/* Responsive styles */
@media (max-width: 768px) {
  .App {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .welcomeHeader {
    font-size: 1.2em; /* Decrease font size for smaller screens */
  }
}
